import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageContainer, Button, Label } from '@yieldstreet/ui-kit';
import { useDiscretionaryFlags } from 'hooks';
import ArrowIcon from 'assets/icons/images/chevron-left.svg';
import Logo from './assets/Logo.png';
import MobileLogo from './assets/MobileLogo.png';
import { Hero } from './Hero';
import { Glance } from './Glance';
import { FeaturedManagers } from './FeaturedManagers';
import { Essentials } from './Essentials';
import { testIds } from './NewYS360.model';
import { Header, BackNavigation, Arrow, LogoContainer, DesktopLogoImg, MobileLogoImg, ContentHolder, ButtonWrapper } from './NewYS360.style';
export const NewYS360 = () => {
  const history = useHistory();
  const {
    showManagedPortfolio,
    hideYs360Lp
  } = useDiscretionaryFlags();
  useEffect(() => {
    if (!showManagedPortfolio) {
      history.push('/360-preview');
    }
  }, [showManagedPortfolio, history]);
  return __jsx(PageContainer, null, __jsx(Header, null, __jsx(BackNavigation, _extends({
    onClick: e => {
      e.preventDefault();
      history.push('/investment-path');
    }
  }, testIds.backNavigation.attr), __jsx(Arrow, {
    src: ArrowIcon
  }), "Back"), __jsx(LogoContainer, testIds.logo.attr, __jsx(DesktopLogoImg, {
    src: Logo
  }), __jsx(MobileLogoImg, {
    src: MobileLogo
  }))), __jsx(ContentHolder, null, __jsx(Hero, null), __jsx(Glance, null), __jsx(FeaturedManagers, null), __jsx(Essentials, null), !hideYs360Lp && __jsx(ButtonWrapper, null, __jsx(Button, _extends({
    buttonType: "secondary",
    onClick: () => history.push('/360'),
    fullWidth: true
  }, testIds.button.attr), "Learn more")), __jsx(Label, _extends({
    small: true
  }, testIds.footnote.attr), "Brokerage services are provided by Atomic Brokerage LLC, a registered broker-dealer and member of FINRA and SIPC. Clearing services are provided by Pershing LLC. For more details about Atomic Brokerage, please see Atomic Brokerage LLC's Form CRS, General Disclosures, Privacy Policy, and BrokerCheck. Neither Atomic Brokerage LLC, nor any of its affiliates, is a bank. Investments in securities are not FDIC insured, not bank guaranteed, and may lose value. Investing involves risks, including the possible loss of principal. There is no assurance that the investments will provide positive performance over any period of time. Before investing, consider your investment objectives, the fees charged to your account, and any management fees associated with your individual investments.")));
};