import { useFlags } from '@yieldstreet/tool-kit';
export const useDiscretionaryFlags = () => {
  const {
    managedOnboardingWeb: showManagedOnboarding,
    managedPortfolioWeb: showManagedPortfolio,
    managedDocumentsWeb: showManagedDocuments,
    managedDepositResubmitWeb: showManagedDepositResubmitWeb,
    portfolioSummaryWeb: showPortfolioSummary,
    managedPortfolioSignup: enableManagedPortfolioSignup,
    hideYs360Lp
  } = useFlags({
    managedPortfolioSignup: false,
    hideYs360Lp: false,
    managedOnboardingWeb: false,
    managedPortfolioWeb: false,
    managedDocumentsWeb: false,
    managedDepositResubmitWeb: false,
    portfolioSummaryWeb: false
  });
  return {
    showManagedOnboarding,
    showManagedPortfolio,
    showManagedDocuments,
    showManagedDepositResubmitWeb,
    showPortfolioSummary,
    enableManagedPortfolioSignup,
    hideYs360Lp
  };
};